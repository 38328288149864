// src/directives/scrollbar.js
import { debounce } from 'lodash'; // Import debounce if using lodash

export default {
  mounted(el) {
    let hideTimeout;

    // Function to show the scrollbar
    const showScrollbar = debounce(() => {
      const style = document.getElementById('hide-scrollbars-style');
      if (style) {
        style.remove();
      }
      if (hideTimeout) {
        clearTimeout(hideTimeout);
      }
      hideTimeout = setTimeout(() => {
        const style = document.createElement('style');
        style.id = 'hide-scrollbars-style'; // Assign an ID

        // Set the CSS content
        style.textContent = `
          *::-webkit-scrollbar {
            background:transparent
          }
          
          ::-webkit-scrollbar-thumb {
            // -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: 5px;  /* Firefox */
            background:transparent
          }
        `;

        // Append the style element to the document head
        document.head.appendChild(style);
      }, 3000);
    }, 100); // Debounced function with 100ms delay

    // Function to hide the scrollbar after a timeout
    const hideScrollbar = debounce(() => {
      if (hideTimeout) {
        clearTimeout(hideTimeout);
      }
      hideTimeout = setTimeout(() => {
        const style = document.createElement('style');
        style.id = 'hide-scrollbars-style'; // Assign an ID

        // Set the CSS content
        style.textContent = `
          *::-webkit-scrollbar {
              background:transparent
          }
          
          ::-webkit-scrollbar-thumb {
            // -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: 5px;  /* Firefox */
            background:transparent
          }
        `;

        // Append the style element to the document head
        document.head.appendChild(style);
      }, 3000);
    }, 100); // Debounced function with 100ms delay

    // Function to add scroll event listeners to all scrollable elements
    const addScrollListeners = (node) => {
      if (node.nodeType === 1) { // Only process element nodes
        node.addEventListener('scroll', showScrollbar);
        node.addEventListener('mouseenter', showScrollbar);
        node.addEventListener('mousemove', showScrollbar);
        node.addEventListener('mouseleave', hideScrollbar);

        // Recursively add listeners to child nodes
        node.querySelectorAll('*').forEach(addScrollListeners);
      }
    };

    // Add scroll listeners to the initially mounted element and its children
    addScrollListeners(el);

    // Use MutationObserver to detect new elements being added to the DOM
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(addScrollListeners);
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Initially show the scrollbar
    el.style.overflow = 'unset';

    // Store the observer for cleanup
    el._observer = observer;
  },
  beforeUnmount(el) {
    // Function to remove scroll event listeners from all elements
    const removeScrollListeners = (node) => {
      if (node.nodeType === 1) { // Only process element nodes
        node.removeEventListener('scroll', el._showScrollbar);
        node.removeEventListener('mouseenter', el._showScrollbar);
        node.removeEventListener('mousemove', el._showScrollbar);
        node.removeEventListener('mouseleave', el._hideScrollbar);

        // Recursively remove listeners from child nodes
        node.querySelectorAll('*').forEach(removeScrollListeners);
      }
    };

    // Remove scroll listeners from the unmounted element and its children
    removeScrollListeners(el);

    // Disconnect the observer to avoid memory leaks
    if (el._observer) {
      el._observer.disconnect();
    }
  }
};
