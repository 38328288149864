<template>
	<div class="time-picker">
		<input type="text" class="time-input" v-model="localDisplayTime" @click="toggleDropdown" readonly
			placeholder="Select Time">
		<div class="dropdown" :class="{ active: dropdownActive }">
			<ul ref="hoursList">
				<li v-for="hour in hours" :key="hour" :class="{ selected: selectedHour === hour }"
					@click="selectTime('hour', hour)">
					{{ hour }}
				</li>
			</ul>
			<ul ref="minutesList">
				<li v-for="minute in minutes" :key="minute" :class="{ selected: selectedMinute === minute }"
					@click="selectTime('minute', minute)">
					{{ minute }}
				</li>
			</ul>
			<ul ref="ampmList">
				<li v-for="ampm in ampmOptions" :key="ampm" :class="{ selected: selectedAmPm === ampm }"
					@click="selectTime('ampm', ampm)">
					{{ ampm }}
				</li>
			</ul>
		</div>
	</div>
</template>
  
<script>
export default {
	name: 'CustomTimePicker',
	props: {
		modelValue: String
	},
	data() {
		return {
			localDisplayTime: '',
			selectedHour: null,
			selectedMinute: null,
			selectedAmPm: null,
			dropdownActive: false,
			hours: Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0')),
			minutes: Array.from({ length: 12 }, (_, i) => String(i * 5).padStart(2, '0')),
			ampmOptions: ['AM', 'PM']
		};
	},
	watch: {
		modelValue: {
			immediate: true,
			handler(newValue) {
				this.parseTime(newValue);
			}
		}
	},
	methods: {
		toggleDropdown() {
			this.dropdownActive = !this.dropdownActive;
			if (this.dropdownActive) {
				this.scrollToSelected();
			}
		},
		closeDropdown() {
			this.dropdownActive = false;
		},
		selectTime(type, value) {
			if (type === 'hour') {
				this.selectedHour = value;
				if (!this.selectedMinute) {
					this.selectedMinute = '00'
				}

			} else if (type === 'minute') {
				this.selectedMinute = value;
			} else if (type === 'ampm') {
				this.selectedAmPm = value;
			}

			if (this.selectedHour && this.selectedMinute && this.selectedAmPm) {
				this.localDisplayTime = `${this.selectedHour}:${this.selectedMinute} ${this.selectedAmPm}`;
				const time24Hour = this.convert12HourTo24Hour(this.selectedHour, this.selectedMinute, this.selectedAmPm);
				this.$emit('update:modelValue', time24Hour);
				this.$emit('input', time24Hour);
				this.$emit('change', time24Hour);
				this.closeDropdown();
			}
		},
		handleClickOutside(event) {
			if (!this.$el.contains(event.target)) {
				this.closeDropdown();
			}
		},
		customScroll(event) {
			event.preventDefault();
			const list = event.currentTarget;
			if (event.deltaY < 0) {
				list.scrollTop -= 40;
			} else {
				list.scrollTop += 40;
			}
		},
		parseTime(input) {
			if (!input) return;
			let time;

			// Check if input is datetime
			if (input.includes('T')) {
				time = input.split('T')[1].split(':').slice(0, 2).join(':');
			} else {
				time = input;
			}

			const [hourMinute, ampm] = this.convert24HourTo12Hour(time);
			const [hour, minute] = hourMinute.split(':');
			this.selectedHour = hour.padStart(2, '0');
			this.selectedMinute = minute.padStart(2, '0');
			this.selectedAmPm = ampm;
			this.localDisplayTime = `${this.selectedHour}:${this.selectedMinute} ${this.selectedAmPm}`;
		},
		convert24HourTo12Hour(time) {
			let [hour, minute] = time.split(':');
			hour = parseInt(hour, 10);
			const isPM = hour >= 12;
			if (hour > 12) hour -= 12;
			if (hour === 0) hour = 12;
			return [`${String(hour).padStart(2, '0')}:${minute}`, isPM ? 'PM' : 'AM'];
		},
		convert12HourTo24Hour(hour, minute, ampm) {
			hour = parseInt(hour, 10);
			if (ampm === 'PM' && hour !== 12) {
				hour += 12;
			} else if (ampm === 'AM' && hour === 12) {
				hour = 0;
			}
			return `${String(hour).padStart(2, '0')}:${minute}`;
		},
		scrollToSelected() {
			this.$nextTick(() => {
				if (this.selectedHour) {
					const selectedHourEl = this.$refs.hoursList.querySelector('.selected');
					if (selectedHourEl) selectedHourEl.scrollIntoView({ block: 'start' });
				}
				if (this.selectedMinute) {
					const selectedMinuteEl = this.$refs.minutesList.querySelector('.selected');
					if (selectedMinuteEl) selectedMinuteEl.scrollIntoView({ block: 'start' });
				}
				if (this.selectedAmPm) {
					const selectedAmPmEl = this.$refs.ampmList.querySelector('.selected');
					if (selectedAmPmEl) selectedAmPmEl.scrollIntoView({ block: 'start' });
				}
			});
		}
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutside);
		this.$refs.hoursList.addEventListener('wheel', this.customScroll);
		this.$refs.minutesList.addEventListener('wheel', this.customScroll);
		this.$refs.ampmList.addEventListener('wheel', this.customScroll);
	},
	beforeUnmount() {
		document.removeEventListener('click', this.handleClickOutside);
		this.$refs.hoursList.removeEventListener('wheel', this.customScroll);
		this.$refs.minutesList.removeEventListener('wheel', this.customScroll);
		this.$refs.ampmList.removeEventListener('wheel', this.customScroll);
	}
};
</script>
  
<style scoped>
.time-picker {
	position: relative;
	display: inline-block;
}

.dropdown {
	display: none !important;
	position: absolute;
	left: 0;
	z-index: 10;
	max-height: 280px;
	display: flex;
	overflow: hidden;
	border-radius: 5px;
    border: 0.5px solid #0E2641;
    background: #FFF;padding: 2px;
}

.dropdown.active {
	display: flex !important;
}

.dropdown ul{list-style: none;padding: 0;margin: 0;width: 33.33%;max-height: 280px;overflow-y: scroll;position: relative;margin-right: 5px;}
.dropdown ul:last-child{margin-right: 0;}

/* Hide scrollbar for Chrome, Safari and Opera */
.dropdown ul::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dropdown ul {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}



.dropdown li:hover{
	background: #F6F1E8;
}
.dropdown li.selected{background: #0E2641;color: #F4D09E;}
</style>
  