import {
	helpers,
	required,
	email,
	requiredIf,
} from "@vuelidate/validators";
import moment from "moment";

const customRequired = helpers.withMessage(
	'Please select specialist or enter fax number',
	(value, vm) => {
		return !!(vm.phoneNumbers.length > 0 || vm.specialist_id.length > 0);
	}
  );

const social_history = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			note: null,
			start_date: null,
			resolution_date: null,
		},
		form: {},
		validationRules: {
			form: {
				start_date: {
					required: helpers.withMessage("Please Select Start Date.", required),
				},
				note: {
					required: helpers.withMessage("Please Enter Note.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
		getters: {},
		mutations: {},
		actions: {},
	}
};

const medical_history = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			note: null,
			start_date: null,
			resolution_date: null,
			procedure_date: null,
			problem_status: null,
			procedure_text: null,
			life_stage: null
		},
		form: {},
		validationRules: {
			form: {
				start_date: {
					required: helpers.withMessage("Please Select Start Date.", required),
				},
				note: {
					required: helpers.withMessage("Please Enter Note.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const ongoing_concerns = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			name: null,
			note: null,
			problem_description: null,
			start_date: null,
			resolution_date: null,
			problem_status: null,
			life_stage: null,
			diagnosis_code_system: null,
			diagnosis_code: null,
			diagnosis_code_description:null
		},
		form: {},
		validationRules: {
			form: {
				note: {
					required: helpers.withMessage("Please Enter Note.", required),
				},
				problem_description:{
					required: helpers.withMessage("Please Enter Problem Description.", required),
				},
				name:{
					required: helpers.withMessage("Please Enter Name.", required),
				},
				start_date:{
					required: helpers.withMessage("Please select start date.", required),
				}
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const reminders = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			note: null,
			encounter_date:null,
			start_date: null,
			resolution_date: null,
			description: null,
		},
		form: {},
		validationRules: {
			form: {
				start_date: {
					required: helpers.withMessage("Please Select Start Date.", required),
				},
				description: {
					required: helpers.withMessage("Please Enter Description.", required),
				},
				// note: {
				// 	required: helpers.withMessage("Please Enter Note.", required),
				// },
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const risk_factors = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			risk_factor_name:null,
			note:null,
			start_date:null,
			resolution_date:null,
			age_at_onset:null,
			exposure_details:null,
			life_stage:null
		},
		form: {},
		validationRules: {
			form: {
				note: {
					required: helpers.withMessage("Please enter note.", required),
				},
				risk_factor_name:{
					required: helpers.withMessage("Please enter risk factoer name.", required),
				},
				start_date:{
					required: helpers.withMessage("Please select start date.", required),
				}
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const family_history = {
	namespaced: true,

	state: {
		defaultFormData: {
			id: null,
			note: null,
			start_date: null,
			resolution_date: null,
			age_at_onset: null,
			treatment: null,
			relationship: null,
			life_stage: null,
			problem_diagnosis_procedure_description: null,
			diagnosis_code_system: null,
			diagnosis_code:null,
			diagnosis_code_description:null,
		},
		form: {},
		validationRules: {
			form: {
				start_date: {
					required: helpers.withMessage("Please Select Start Date.", required),
				},
				relationship: {
					required: helpers.withMessage("Please Select Any Relationship.", required),
				},
				note: {
					required: helpers.withMessage("Please Enter Note.", required),
				},
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const prevention = {
	namespaced: true,

	state: {
		defaultFormData: {
			prevention_id:null,
			code:null,
			brand_name:null,
			type:null,
			date:moment().format('YYYY-MM-DD'),
			status:'Completed',
			isRefused:0,
			manufacturer:null,
			lotNumber:null,
			route:null,
			site:null,
			dose:null,
			instructions:null,
			notes:null,
			keyword:null,
			advanceFilter:null,
			id:null
		},
		form: {
			prevention_id:null,
			code:null,
			brand_name:null,
			type:null,
			date:null,
			status:null,
			isRefused:null,
			manufacturer:null,
			lotNumber:null,
			route:null,
			site:null,
			dose:null,
			instructions:null,
			notes:null,
			keyword:null,
			advanceFilter:null,
			id:null
		},
		validationRules: {
			form: {
				date: {
					required: helpers.withMessage("Please select date.", required),
				},
				prevention_id:{
					required: helpers.withMessage("Please select vaccine.", required),
				},
				brand_name:{
					required: helpers.withMessage("Please enter brand name.", required),
				},
				lotNumber:{
					required: helpers.withMessage("Please enter lot.", required),
				},
				dose:{
					required: helpers.withMessage("Please enter dose.", required),
				},
				code:{
					required: helpers.withMessage("Please enter din.", required),
				}
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const patient_document = {
	namespaced: true,

	state: {
		listUrl: "/patient/document/list",
		header: [
			{ "description": "Document Description" },
			{ "creator": "Content" },
			{ "service_date": "Type" },
			{ "created_datetime": "Creator" },
			{ "priority": "Responsible" },
			{ "provider_ids": "Date" },
			{ "status": "Reviewer" },
			{ "status": "Action" },
		],
		pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
			id: null,
			comments: null,
			provider_ids: null,
			status: null,
			report_class_id:null,
			tracing_role: null,
			tracing_role_text:"Select Status"
		},
		form: {
			tracking_request:null,
			tracking_request_text:"Select Link with Tracing Requests",
			report_class_id:null,
			annotatedPdfBytes: null,
			tracing_role: null,
			tracing_role_text:"Select Status"
		},
		validationRules: {
			form: {
				description: {
					required: helpers.withMessage("Please enter document description.", required),
				},
				report_class_id:{
					required: helpers.withMessage("Please select document type.", required),
				},
				tracking_request:{
					required: helpers.withMessage("Please select link with tracing requests.", requiredIf(function() {
                        return (this.$store.state.patient_document.form.report_class_id != 'Rx' && this.$store.state.patient_document.form.report_class_id != 'Others') ? true : false;
                    })),
				},
				tracing_role:{
					required: helpers.withMessage("Please select status.", requiredIf(function() {
                        return (this.$store.state.patient_document.form.report_class_id != 'Rx' && this.$store.state.patient_document.form.report_class_id != 'Others') ? true : false;
                    })),
				},
				patient_id:{
					required: helpers.withMessage("Please select demographic.", required),
				}
			},
			mailForm:{
				name:{
					required: helpers.withMessage("Please enter name.", required),
				},
				address:{
					required: helpers.withMessage("Please enter address.", required),
				},
				city:{
					required: helpers.withMessage("Please enter city.", required),
				},
				state:{
					required: helpers.withMessage("Please enter state.", required),
				},
				zip:{
					required: helpers.withMessage("Please enter zip.", required),
				}
			},
			faxForm:{
				phoneNumbers:{
					customRequired,
				},
			},
			emailForm:{
				email_header:{
					required: helpers.withMessage("Please enter email header.", required),
				},
				email_id:{
					required: helpers.withMessage("Please enter email id.", required),
					email: helpers.withMessage("Please enter valid email", email),
				}
			}
		},
		filter:{
			'observation_date':'',
		},
		faxForm:{
			specialist_id:null,
			fax_number:null,
			phoneNumbers:[]
		},
		emailForm:{
			email_id:null,
			email_header:null
		},
		mailForm:{
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null
		},
		defaultexportfrm:{
			specialist_id:null,
			fax_number:null,
			email_id:null,
			email_header:null,
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null,
			phoneNumbers:[]

		}
	},

	getters: {},
	mutations: {},
	actions: {},
};

const encounter_note = {
	namespaced: true,

	state: {
		defaultFormData: {
			encounter_datetime: new Date(),
			note: '['+new Date().toISOString().slice(0, 10)+' '+new Date().toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', second:'2-digit' })+': Tel-Progress Note]',
			encounter_type:''
		},
		form: {
			encounter_datetime: new Date(),
			note: '['+new Date().toISOString().slice(0, 10)+' '+new Date().toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', second:'2-digit' })+': Tel-Progress Note]',
			encounter_editors:localStorage.getItem('authUserName'),
			encounter_type:''
		},
		validationRules: {
			form: {
				note:{
					required: helpers.withMessage("Please enter encounter note.", required),
				},
				encounter_datetime:{
					required: helpers.withMessage("Please select encounter date.", required),
				},
				encounter_type:{
					required: helpers.withMessage("Please select encounter type.", required),
				}
			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const screening= {
	namespaced: true,

	state: {
		defaultFormData: {
			id:null,
			patient_id:null,
			screening_type:null,
			start_date:moment().format('YYYY-MM-DD'),
			next_date:null,
			result:'Normal',
			comments:null,
			instructions:null,
			isRefused:0,
			keyword:null,
			advanceFilter:null,
		},
		form: {
			id:null,
			patient_id:null,
			screening_type:null,
			start_date:null,
			next_date:null,
			result:null,
			comments:null,
			instructions:null,
			isRefused:null,
			keyword:null,
			advanceFilter:null,
		},
		validationRules: {
			form: {
				screening_type:{
					required: helpers.withMessage("Please enter screening type.", required),
				},

			},
		},
		vuelidateExternalResults: {
			form: {

			}
		},
	},

	getters: {},
	mutations: {},
	actions: {},
};

const lab = {
	namespaced: true,
	state: {
		faxForm:{
		},
		emailForm:{
			email_id:null,
			email_header:null
		},
		mailForm:{
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null
		},
		validationRules: {
			mailForm:{
				name:{
					required: helpers.withMessage("Please enter name.", required),
				},
				address:{
					required: helpers.withMessage("Please enter address.", required),
				},
				city:{
					required: helpers.withMessage("Please enter city.", required),
				},
				state:{
					required: helpers.withMessage("Please enter state.", required),
				},
				zip:{
					required: helpers.withMessage("Please enter zip.", required),
				}
			},
			faxForm:{
				phoneNumbers:{
					customRequired,
				},
			},
			emailForm:{
				email_header:{
					required: helpers.withMessage("Please enter email header.", required),
				},
				email_id:{
					required: helpers.withMessage("Please enter email id.", required),
					email: helpers.withMessage("Please enter valid email", email),
				}
			}
		},
		defaultexportfrm:{
			specialist_id:null,
			fax_number:null,
			email_id:null,
			email_header:null,
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null,
			phoneNumbers:[]

		}
	}
};

const imaging = {
	namespaced: true,
	state: {
		faxForm:{
			specialist_id:null,
			fax_number:null,
			phoneNumbers:[]
		},
		emailForm:{
			email_id:null,
			email_header:null
		},
		mailForm:{
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null
		},
		validationRules: {
			mailForm:{
				name:{
					required: helpers.withMessage("Please enter name.", required),
				},
				address:{
					required: helpers.withMessage("Please enter address.", required),
				},
				city:{
					required: helpers.withMessage("Please enter city.", required),
				},
				state:{
					required: helpers.withMessage("Please enter state.", required),
				},
				zip:{
					required: helpers.withMessage("Please enter zip.", required),
				}
			},
			faxForm:{
				phoneNumbers:{
					customRequired,
				},
			},
			emailForm:{
				email_header:{
					required: helpers.withMessage("Please enter email header.", required),
				},
				email_id:{
					required: helpers.withMessage("Please enter email id.", required),
					email: helpers.withMessage("Please enter valid email", email),
				}
			}
		},
		defaultexportfrm:{
			specialist_id:null,
			fax_number:null,
			email_id:null,
			email_header:null,
			name:null,
			address:null,
			city:null,
			state:null,
			zip:null,
			phoneNumbers:[]

		}
	}
};


const disease_reg = {
	namespaced: true,
	state: {
		defaultFormData: {
		},
		form:{

		},
		validationRules: {
			form: {
				notes: {
					required: helpers.withMessage("Please enter notes.", required),
				},
				diagnosis:{
					required: helpers.withMessage("Please enter diagnosis.", required),
				},
				start_date:{
					required: helpers.withMessage("Please select start date.", required),
				}
			},
		}
	}
};

export { social_history, medical_history, ongoing_concerns, reminders, risk_factors, family_history, prevention, patient_document, encounter_note, screening, lab, imaging, disease_reg };